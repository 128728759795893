import type { AppProps } from 'next/app'
import localFont from 'next/font/local'

import { Inter } from 'next/font/google'
import { ToastContainer } from 'react-toastify'
import { useMedia } from 'react-use'
import { SharedLoadingIndicatorContextProvider, SharedProgressLoadingIndicator } from 'shared-loading-indicator'
import { PageNavigationLoadingTracker } from '../components/PageNavigationLoadingTracker'
import '../styles/globals.sass'
import { api } from '../utilities/api'

const inter = Inter({
	subsets: ['latin', 'latin-ext'],
})

const georgia = localFont({
	src: [
		{
			path: '../../public/fonts/georgia-webfont.woff2',
			weight: '400',
		},
	],
})

function MyApp({ Component, pageProps }: AppProps) {
	const theme = useMedia('(prefers-color-scheme: dark)', false) ? 'dark' : 'light'

	return (
		<>
			<style jsx global>{`
				:root {
					--font-georgia: ${georgia.style.fontFamily};
					--font-inter: ${inter.style.fontFamily};
				}
			`}</style>
			<SharedLoadingIndicatorContextProvider>
				<PageNavigationLoadingTracker />
				<SharedProgressLoadingIndicator />
				<ToastContainer theme={theme} />
				<Component {...pageProps} />
			</SharedLoadingIndicatorContextProvider>
		</>
	)
}

export default api.withTRPC(MyApp)
